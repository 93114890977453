<template>
  <div
    class="d-flex align-center item"
    @click.capture.stop="$emit('click', $event)"
  >
    <document-ref-icon
      class="mr-2"
      :icon-name="iconName"
      :color="color"
      visible
    />
    <div class="d-flex flex-column gap flex-grow-1">
      <document-picker-list-item-ref
        class="lightened-account-name"
        v-if="!noDocRef"
        :item="item"
        emphasis
        ref="document-ref"
      />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'search-item',
  inheritAttrs: false,
  components: {
    DocumentPickerListItemRef: () => import('@/components/document-picker-list-item-ref'),
    DocumentRefIcon: () => import('@/components/document-ref-icon')
  },
  async created () {
    if (this.item.type !== 'accounts') { return }
    const { getDoc, CacheType } = await import('@/wasm/pkg')
    const doc = await getDoc(CacheType.AccountRef, this.item.id)
    this.color = doc.color
  },
  computed: {
    iconName () {
      return `i.${this.item.type[0].toUpperCase()}${this.item.type.slice(1)}`
    }
  },
  data () {
    return {
      color: undefined
    }
  },
  methods: {
    goToAccount (item) {
      this.$router.push({ name: 'mobility-account', params: { id: item.id } })
    }
  },
  props: {
    noDocRef: Boolean,
    item: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="stylus" scoped>
.item
  width 100%

.lightened-account-name
  opacity 0.8
</style>
